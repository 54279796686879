/*! ===================================
 *  Author: Nazarkin Roman, Egor Dankov
 *  -----------------------------------
 *  PuzzleThemes
 *  =================================== */

'use strict';

/***********************************************
 * Mailchimp subscription ajax form
 ***********************************************/
(function($) {

    $.ajaxChimp.translations.soprano = {
        'submit': 'Loading, please wait...',
        0: 'We have sent you a confirmation email. Please check your inbox.',
        1: 'The field is empty. Please enter your email.',
        2: 'An email address must contain a single "@" character.',
        3: 'This email seems to be invalid. Please enter a correct one.',
        4: 'This email seems to be invalid. Please enter a correct one.',
        5: 'This email address looks fake or invalid. Please enter a real email address.'
    };

    $('.sp-subscribe-form').each(function () {
        var $form = $(this).closest('form');

        $form.on('submit', function () {
            $form.addClass('mc-loading');
        });

        $form.ajaxChimp({
            language: 'soprano',
            label   : $form.find('> .form-output'),
            callback: function (resp) {
                $form.removeClass('mc-loading');
                $form.toggleClass('mc-valid', (resp.result === 'success'));
                $form.toggleClass('mc-invalid', (resp.result === 'error'));

                if (resp.result === 'success') {
                    $form.find('input[type="email"]').val('');
                }

                setTimeout(function () {
                    $form.removeClass('mc-valid mc-invalid');
                    $form.find('input[type="email"]').focus();
                }, 4500);
            }
        });
    });

})(jQuery);